// src/routes/index.tsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from './config';
import BaseLayout from '../layouts/BaseLayout';
import { useAuth } from '../context/AuthContext';
import { Box, Container, Typography } from '@mui/material';

const MainRoutes = () => {
    return (
        <Routes>
            {routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        element={<route.component />}
                    />
                );
            })}
        </Routes>
    );
};

// Create a new component for protected routes
const ProtectedRoutes = () => {
    const { isUserAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return (
            <Container className="auth-status-container">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    {/* {LottieView} */}
                    <Typography variant="h6" style={{ marginTop: '1rem' }}>
                        Loading...
                    </Typography>
                </Box>
            </Container>
        );
    }

    return isUserAuthenticated ? (
        <BaseLayout>
            <MainRoutes />
        </BaseLayout>
    ) : (
        <Navigate to="/login" replace />
    );
};

export default ProtectedRoutes;
