import { useState, useEffect, useCallback } from 'react';
import { loginPromise, checkUserAuthPromise } from '../api/auth';
import { tokenStore } from '../db';

const isDevEnv = process.env.REACT_APP_ENV === 'development';

const useUserAuth = () => {
    const [user, setUser] = useState<string | null>(null);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // function to check if the user is authenticated
    const checkUserAuthStatus = useCallback(async () => {
        try {
            const response = await checkUserAuthPromise();
            setIsUserAuthenticated(true);
        } catch (error) {
            console.error('Error checking user auth status:', error);
            setIsUserAuthenticated(false);
            setUser(null);
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Run this function when the app loads
    useEffect(() => {
        checkUserAuthStatus();
    }, []);


    const userLogin = async (username: string, password: string) => {
        try {
            const response = await loginPromise(username, password);
            setIsUserAuthenticated(true);
            
        } catch (error) {
            console.error('Login failed:', error);
            setIsUserAuthenticated(false);
        }
    }

    const userLogout = async () => {
        setIsUserAuthenticated(false);
        // if (isDevEnv) {
        //     await tokenStore.removeToken('userAccessToken');
        //     await tokenStore.removeToken('googleAccessToken');
        // }
    }

    return {
        user,
        setUser,
        isUserAuthenticated,
        setIsUserAuthenticated,
        userLogin,
        userLogout,
        isLoading,
    };
};

export default useUserAuth;
