// src/routes/config.ts
// import views from "../views";
import Dashboard from "../views/Dashboard";
import Setting from "../views/Setting";
import VerificationPage from "../views/Verification";
import DataIntegration from "../views/Integration";

// Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import AnalyticsIcon from "@mui/icons-material/Analytics";

const routes = [
	{
		path: "/",
		component: Dashboard,
		exact: true,
		name: "Dashboard",
		icon: DashboardIcon,
		image: "https://source.unsplash.com/random",
		description: "This is the dashboard page",
	},
	{
		path: "/setting",
		component: Setting,
		name: "Setting",
		icon: SettingsIcon,
		image: "https://source.unsplash.com/random",
		description: "This is the settings page",
	},
	{
		path: "/verification",
		component: VerificationPage,
		name: "Data Verification",
		icon: AnalyticsIcon,
		image: "https://source.unsplash.com/random",
		description: "This is the data verification page",
	},
	{
		path: "/integration",
		component: DataIntegration,
		name: "Data Integration",
		icon: AnalyticsIcon,
		image: "https://source.unsplash.com/random",
		description: "This is the data integration page",
	}
];

export default routes;
