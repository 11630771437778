// page where the user can upload the reference data
// https://medium.com/@mohammedshameemke/google-picker-api-integration-to-reactjs-mui-typescript-6c7461aa4598
// https://developers.google.com/drive/picker/guides/overview
import React, {useContext, useState, useEffect, useRef, useMemo, useCallback} from 'react';
import { LinearProgress } from '@mui/material';
import {
	Typography,
	Box,
	Button,
	List,
	ListItemText,
	ListItem,
	IconButton,
	Collapse,
	CircularProgress,
	FormGroup, FormControl, FormLabel, TextField, FormControlLabel, Checkbox, MenuItem, Menu
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { ErrorMessage } from "../../../context/DataVerificationContext";
import { FileMetadata, Designation } from "../../../types";
import {
	CloudUpload as CloudUploadIcon,
	Delete as DeleteIcon,
	InsertDriveFile as FileIcon,
	UnfoldMore as UnfoldMoreIcon,
	UnfoldLess as UnfoldLessIcon,
	Description as DescriptionIcon,
	UploadFile as UploadFileIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles'; 
import useDrivePicker from 'react-google-drive-picker';
// import { useGoogleDriveAuth } from '../../../hooks/useGoogleDriveAuth';
import { useAuth } from '../../../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';

const SelectedFileList = ({
	files,
	setFiles
}: {
		files: Array<FileMetadata>,
		setFiles: React.Dispatch<React.SetStateAction<Array<FileMetadata>>>
	}) => {
	
	const [expanded, setExpanded] = useState({} as { [key: string]: boolean });
	useEffect(() => {
		console.log("Selected Files: ", files);
	}, [files]);
	
	const handleToggleCollapse = (fileMetadata: FileMetadata) => {
		setExpanded((prevExpanded) => ({
			...prevExpanded,
			[fileMetadata.name]: !prevExpanded[fileMetadata.name]
		}));
	}
	
	const handleRemoveFile = (file: FileMetadata) => {
		setFiles(files.filter((selectedFile) => selectedFile !== file));
	}

	const handleIsFinancialData = (fileMetadata: FileMetadata, event: React.ChangeEvent<HTMLInputElement>) => {
		setFiles(files.map((selectedFileMetadata) => {
			if (selectedFileMetadata.name === fileMetadata.name) {
				return {
					...selectedFileMetadata,
					containsFinancialData: event.target.checked
				}
			}
			return selectedFileMetadata;
		}));
	}

	const handleDescriptionChange = (file: FileMetadata, event: React.ChangeEvent<HTMLInputElement>) => {
		setFiles(files.map((selectedFile) => {
			if (selectedFile.name === file.name) {
				return {
					...selectedFile,
					description: event.target.value
				}
			}
			return selectedFile;
		}));
	}

	return (
		<List dense={true}>
			{files.map((fileMetadata) => (
				<ListItem
					key={fileMetadata.id}
					divider={true}
				>
					<Box display="flex" flexDirection="column" width="100%">
            			<Box display="flex" alignItems="center" justifyContent="space-between">
							<IconButton edge="start" size="large" color="primary" aria-label="file-metadata-icon">
								<DescriptionIcon />
							</IconButton>
							<ListItemText primary={fileMetadata.name} />
							<IconButton
								aria-label="expand-more"
								onClick={() => handleToggleCollapse(fileMetadata)}
							>
								{ expanded[fileMetadata.name] ? <UnfoldLessIcon /> : <UnfoldMoreIcon /> }
							</IconButton>
							<IconButton
								edge="end"
								aria-label="delete"
								onClick={() => handleRemoveFile(fileMetadata)}
							>
								<DeleteIcon />
							</IconButton>
						</Box>
						<Collapse in={expanded[fileMetadata.name]} timeout="auto" unmountOnExit>
							<FormGroup>
								<TextField
									placeholder="e.g. Extract the name, address, and phone number of the customers."
									fullWidth
									variant="outlined"
									size="small"
									multiline
									rows={4}
									required
									defaultValue={fileMetadata.description}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDescriptionChange(fileMetadata, event)}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={fileMetadata.containsFinancialData}
											onChange={(event) => handleIsFinancialData(fileMetadata, event)}
											name="financial-data-indicator"
										/>
									}
									label={
										<Typography component="span">
											<strong>Custom Feature</strong>: Financial Data
										</Typography>
									}
								/>
							</FormGroup>
						</Collapse>
					</Box>
				</ListItem>
			))}
		</List>
	)
}

const FileSelect = ({
    selectedFiles,
    setSelectedFiles,
    errors,
    setErrors,
	instructions,
	designation
}: {
		selectedFiles: Array<FileMetadata>,
		setSelectedFiles: React.Dispatch<React.SetStateAction<Array<FileMetadata>>>,
		errors: Array<ErrorMessage>,
		setErrors: React.Dispatch<React.SetStateAction<Array<ErrorMessage>>>,
		instructions: string, 
		designation: Designation
	}) => {
	const { isGoogleUserAuthenticated, handleGoogleAuthProcess, googleAccessToken } = useAuth();
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [openPicker, authResult] = useDrivePicker();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	// const [progress, setProgress] = useState(0);
	// const [progress, setProgress] = useState<{ [key: string]: number }>({});

	const handleLocalUploadMenuItemClicked = useCallback(() => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}

		setAnchorEl(null);
	}, [fileInputRef]);

	const handleLocalUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		// handle file select here
		const newFiles = Array.from(event.target.files || []).filter(
			(newFile) => !selectedFiles.some((f) => f.name === newFile.name)
		)
	
		if (newFiles.length === 0) return;

		newFiles.forEach((file) => {
			const metadata: FileMetadata = {
				id: uuidv4(),
				name: file.name,
				designation: designation,
				type: file.name.split('.').pop() || "",
				mimeType: file.type,
				size: file.size,
				description: "",
				containsFinancialData: false,
				dataProvider: "local",
				iconUrl: "",
				blobUrl: URL.createObjectURL(file)
			}

			setSelectedFiles((prevFiles) => [...prevFiles, metadata]);
		});
	}, [selectedFiles, setSelectedFiles]);

	const openGooglePicker = useCallback(() => {
		if (!isGoogleUserAuthenticated) {
			handleGoogleAuthProcess();
			return;
		}

		if (!googleAccessToken) {
			console.error('Access token not found.');
			return;
		}

		openPicker({
			clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
			developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY as string,
			viewId: 'DOCS',
			token: googleAccessToken,
			showUploadView: true,
			showUploadFolders: true,
			supportDrives: true,
			multiselect: true,
			callbackFunction: (data: any) => {
				if (data.action === 'picked') {
					data.docs.forEach((doc: any) => {
						const metadata: FileMetadata = {
							id: doc.id,
							name: doc.name,
							designation: designation,
							type: doc.name.split('.').pop() || "",
							mimeType: doc.mimeType,
							size: doc.sizeBytes,
							description: "",
							containsFinancialData: false,
							dataProvider: "googledrive",
							iconUrl: doc.iconUrl,
							blobUrl: doc.url
						}

						setSelectedFiles((prevFiles) => [...prevFiles, metadata]);
					});
				}
			},
		});
	}, [googleAccessToken, isGoogleUserAuthenticated, handleGoogleAuthProcess, openPicker, setSelectedFiles]);

	const handleGoogleDriveMenuItemClicked = useCallback(() => {
		setAnchorEl(null);
		openGooglePicker();
	}, [openGooglePicker]);
	
    return (
		<Box sx={{
			display: "flex",
			flexDirection: "column",
			alignItems: 'center',
			position: 'relative',
		}}>
            <Typography variant="body1" gutterBottom>
				<p dangerouslySetInnerHTML={{ __html: instructions }} />
			</Typography>
			
			<Button
				aria-label='Select Files'
				component="label"
				variant="text"
				startIcon={<FolderIcon />}
				onClick={(event) => setAnchorEl(event.currentTarget)}
			>
				Select Files
			</Button>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				<MenuItem onClick={handleLocalUploadMenuItemClicked}>
					<UploadFileIcon /> Upload from computer
				</MenuItem>
				<MenuItem onClick={handleGoogleDriveMenuItemClicked}>
					<CloudUploadIcon /> Upload from Google Drive
				</MenuItem>
			</Menu>
			<input
				id="local-file-upload"
				type="file"
				multiple
				accept=".pdf,.csv,.xlsx,.docx,.pptx"
				onChange={handleLocalUpload}
				ref={fileInputRef}
				style={{ display: 'none' }}
			/>

			{/* Conditionally render the progress bar only if progress is being made */}
			{/* {progress > 0 && progress < 100 && (
            <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
        	)} */}

			<Box sx={{
				border: '2px dashed #ccc',
				width: '100%',
				height: '45vh',
				overflowY: 'auto',
			}}>	
				<SelectedFileList
					files={selectedFiles}
					setFiles={setSelectedFiles}
				/>
				
			</Box>
			{selectedFiles.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                        File path: {String(selectedFiles[0].blobUrl)}
                    </Typography>
                </Box>
            )}
        </Box>
	
    );
};

export default FileSelect;