import { AxiosResponse } from "axios";
import { axiosInstance } from "./AxiosInstance";
import { AssignmentPostRequestBody } from "../types";

export const getAssignments = async (user_id: string) => {
	return await axiosInstance.get(`/assignments/history/${user_id}`);
};

export const createAssignment = async (assignment: AssignmentPostRequestBody): Promise<AxiosResponse> => {
	return await axiosInstance.post("/assignments/", assignment);
};

export const getAssignment = async (assignment_id: string): Promise<AxiosResponse> => {
	return await axiosInstance.get(`/assignments/${assignment_id}`);
};
