import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardHeader, CardActions, CardContent, useTheme, Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText, LinearProgress, IconButton, TextField, Grid, Chip, MenuItem, Divider } from '@mui/material';
import { CheckCircle, CloudUpload as CloudUploadIcon, Delete as DeleteIcon, InsertDriveFile as FileIcon } from '@mui/icons-material';
import { IntegrationContainer } from './style';
import { FileUploadResponse } from '../../types';
import { styled } from '@mui/material/styles';
// import SparkMD5 from 'spark-md5';
import { readFileContent } from '../../utils/files';
import { uploadToFirebasePromise, getFileHashes, getFiles } from '../../api/files';
import useIntegrations from '../../hooks/useIntegrations';

const Input = styled('input')({
    display: 'none',
});


const FirebaseIntegration = () => {
    const [existingFiles, setExistingFiles] = useState<FileUploadResponse[]>([]); // store existing files from the backend
    const [existingHashes, setExistingHashes] = useState<{ [key: string]: string }>({}); // store existing file hashes

    useEffect(() => {
        // fetch existing files hashes from the backend
        // getFileHashes().then((response) => {
        //     setExistingHashes(response.data);
        // });

        // fetch existing files from the backend
        // getFiles().then((response) => {
        //     setExistingFiles(response.data);
        // });
    }, []);


    // const calculateFileHash = (file: File): Promise<string> => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = (event) => {
    //             const content = event.target?.result;
    //             if (content) {
    //                 const hash = SparkMD5.ArrayBuffer.hash(content as ArrayBuffer);
    //                 resolve(hash);
    //             } else {
    //                 reject('Error reading file content');
    //             }
    //         };
    //         reader.onerror = (error) => {
    //             reject(error);
    //         };
    //         reader.readAsArrayBuffer(file);
    //     });
    // }

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // handle file select here
        const newFiles = Array.from(event.target.files || []).filter(
            (file) => !existingFiles.some((f) => f.name === file.name)
        )

        if (newFiles.length === 0) return;

        // const nonDuplicateFiles: File[] = [];
        // for (const file of newFiles) {
        //     const fileHash = await calculateFileHash(file);
        //     if (!Object.values(existingHashes).includes(fileHash)) {
        //         nonDuplicateFiles.push(file);
        //     } else {
        //         alert(`File ${file.name} already exists`);
        //     }
        // }

        const filesToUpload = newFiles.map((file) => ({
            name: file.name,
            type: file.type,
            size: file.size,
            path: '',
            created: new Date(),
            hash: '',
            status: 'uploading',
            metadata: {},
        }));
        
        setExistingFiles((prevFiles) => [...prevFiles, ...filesToUpload]);

        const formData = new FormData();
        newFiles.forEach((file) => {
            formData.append("files", file);
        });

        uploadToFirebasePromise(formData).then((response) => {
            console.log(response);
            const uploaded_files_info: FileUploadResponse[] = response.data.uploaded_files_info;
            setExistingFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                    uploaded_files_info.some((file) => file.name === prevFile.name) ? { ...prevFile, status: 'success' } : prevFile
                )
            );
        }).catch((error) => {
            console.log(error);
            setExistingFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                    filesToUpload.some((file) => file.name === prevFile.name) ? { ...prevFile, status: 'error' } : prevFile
                )
            );
        });     
    }

    const handleDelete = (fileName: string) => {
        // handle file delete here
        setExistingFiles(existingFiles.filter((file) => file.name !== fileName));

        // delete file from the backend
        // deleteFile(fileName).then((response) => {
    }

    return (
        <Card sx={{ display: "flex", borderRadius: '12px', overflow: 'hidden', padding: "20px"}}>
            <Box
                sx={{
                    border: '2px dashed #90caf9',
                    borderRadius: '8px',
                    padding: '20px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1
                }}
            >
                <CardContent>
                    <Box sx={{ textAlign: 'center' }}>
                        <CloudUploadIcon fontSize='large' sx={{ mb: 2 }} />
                        <Typography variant="h6">Drag and Drop files to upload</Typography>
                        <Typography variant="body2">or</Typography>
                        <label htmlFor="file-upload">
                            <Input id="file-upload" type="file" multiple onChange={handleUpload} />
                            <Button variant="contained" color="primary" component="span">
                                Browse
                            </Button>
                        </label>
                        <Typography variant="body2" sx={{ marginTop: '10px' }}>
                            Supported files: PDF, CSV, XLSX, DOCX, PPTX
                        </Typography>
                    </Box>
                </CardContent>
            </Box>
            <Box sx={{ flex: 1, padding: '20px' }}>
                <CardContent>
                    <Typography variant="h6" sx={{ mb: 2 }}>Uploaded Files</Typography>
                    <List>
                        {existingFiles.map((file) => (
                            <ListItem key={file.name} sx={{
                                display: 'flex', 
                                alignItems: 'center',
                            }}>
                                <ListItemIcon>
                                    <FileIcon />
                                </ListItemIcon>
                                <ListItemText primary={file.name} />
                                {file.status === 'uploading' && (
                                    <LinearProgress sx={{ width: '100%', marginRight: '10px' }} />
                                )}
                                {file.status === 'error' && (
                                    <Typography color="error" sx={{ marginRight: '10px' }}>
                                        Upload failed
                                    </Typography>
                                )}
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file.name)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Box>
        </Card>
    )
}

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
    flexGrow: 1,
}));

const IntegrationPage = () => {
    const theme = useTheme();
    const { integrations } = useIntegrations();

    return (
        <IntegrationContainer theme={theme}>
            <Box my={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h4" component="div">
                        Integrations
                    </Typography>
                    <TextField
                        label="Search integration..."
                        variant="outlined"
                        size="small"
                    />
                </Box>
                <Grid container spacing={3}>
                    {integrations.map((integration, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <StyledCard>
                                <CardContentStyled>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                        <Box display="flex" alignItems="center">
                                            <img src={integration.logo} alt={integration.name} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                                            <Typography variant="h6" component="div" noWrap>
                                                {integration.name}
                                            </Typography>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color={integration.status === 'Disconnect' ? 'error' : 'success'}
                                            sx={{ textTransform: 'none' }}
                                            onClick={() => integration.onClickHandler()}
                                        >
                                            {integration.status}
                                        </Button>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                    >
                                        {integration.description}
                                    </Typography>
                                </CardContentStyled>
                            </StyledCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </IntegrationContainer>
    );
}

export default IntegrationPage;