import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper, Grid, Divider, Card, CardContent, IconButton } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { FileMetadata as SelectedFile } from '../../../types';

// Component to display the list of selected files
const SelectedFileList: React.FC<{ files: Array<SelectedFile> }> = ({ files }) => {
    return (
        <Card variant="outlined" sx={{ height: '45vh', overflowY: 'auto', backgroundColor: '#f9f9f9' }}>
            <CardContent>
                <List>
                    {files.map((file) => (
                        <ListItem key={file.name}>
                        <IconButton edge="start" size="large" color="primary" aria-label="file-icon">
                            <DescriptionIcon />
                        </IconButton>
                        <ListItemText primary={file.name} />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

    // Main ReviewPage component
const ReviewPage: React.FC<{
    userQuery: string,
    selectedReferenceFiles: Array<SelectedFile>,
    selectedTargetFiles: Array<SelectedFile>
}> = ({ userQuery, selectedReferenceFiles, selectedTargetFiles }) => {
    return (
        <Box sx={{ padding: '2rem', backgroundColor: '#f5f5f5', minHeight: '100vh', minWidth: '60vw' }}>
            <Box sx={{ marginBottom: '2rem', padding: '1.5rem', backgroundColor: 'white', borderRadius: '8px', boxShadow: 1 }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    Your Query:
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Typography variant="body1">
                    {userQuery}
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                        Reference Files:
                    </Typography>
                    <SelectedFileList files={selectedReferenceFiles} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                        Target Files:
                    </Typography>
                    <SelectedFileList files={selectedTargetFiles} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReviewPage;
