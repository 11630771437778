// src/services/fileProcessingManager.ts
import { workerStatesStore } from '../db';
import { WorkerStateService } from './IndexedDBService';
import { FileMetadata } from '../types';

export interface ProgressUpdate {
    fileId: string;
    progress: number;
}

export interface WorkerMessage {
    type: 'progress' | 'complete' | 'error';
    fileId: string;
    processedSize?: number;
    totalSize?: number;
    error?: string;
}

class FileProcessingManager {
    private activeWorkers: Map<string, Worker> = new Map();
    private workerQueue: FileMetadata[] = [];
    private workerStates: WorkerStateService = workerStatesStore;
    private MAX_CONCURRENT_WORKERS = 5;


    // async initializeFromIndexedDB() {
    //     const workerStates = await this.workerStates.getAllWorkerStates();
    //     workerStates.forEach((state) => {
    //         if (state.status === 'processing') {
    //             this.startWorkerForFile(state.fileId);
    //         }
    //         this.onProgressUpdate({ fileId: state.fileId, progress: state.progress });
    //     });
    // }

    addFileToQueue(fileMetadata: FileMetadata) {
        if (this.activeWorkers.size < this.MAX_CONCURRENT_WORKERS) {
            this.startWorkerForFile(fileMetadata);
        } else {
            this.workerQueue.push(fileMetadata);
        }
        console.log('Queue:', this.workerQueue);
    }

    private startWorkerForFile(fileMetadata: FileMetadata) {
        const worker = new Worker(new URL('../workers/fileProcessor.worker.ts', import.meta.url), { type: 'module' });
        this.activeWorkers.set(fileMetadata.id, worker);

        worker.onmessage = async (e: MessageEvent<WorkerMessage>) => {
            if (e.data.type === 'progress') {
                const progress = (e.data.processedSize! / e.data.totalSize!) * 100;
                this.onProgressUpdate({ fileId: e.data.fileId, progress });
                await this.workerStates.setWorkerState({
                    fileId: e.data.fileId,
                    status: 'processing',
                    progress,
                });
            } else if (e.data.type === 'complete') {
                this.onProcessingComplete(e.data.fileId);
                await this.workerStates.setWorkerState({
                    fileId: e.data.fileId,
                    status: 'complete',
                    progress: 100,
                });
                this.activeWorkers.delete(e.data.fileId);
                this.processNextInQueue();
            } else if (e.data.type === 'error') {
                this.onProcessingError(e.data.fileId, e.data.error!);
                await this.workerStates.setWorkerState({
                    fileId: e.data.fileId,
                    status: 'terminated',
                    progress: 0,
                });
                this.activeWorkers.delete(e.data.fileId);
                this.processNextInQueue();
            }
        };

        worker.onerror = async (error) => {
            console.error('Worker error:', error);
            this.onProcessingError(fileMetadata.id, 'Unknown error occurred');
            await this.workerStates.setWorkerState({
                fileId: fileMetadata.id,
                status: 'terminated',
                progress: 0,
            });
            this.activeWorkers.delete(fileMetadata.id);
            this.processNextInQueue();
        };

        worker.postMessage({ fileMetadata });
    }

    private processNextInQueue() {
        if (this.activeWorkers.size < this.MAX_CONCURRENT_WORKERS && this.workerQueue.length > 0) {
            const nextFile = this.workerQueue.shift()!;
            this.startWorkerForFile(nextFile);
        }
    }

    private onProgressUpdate(progressUpdate: ProgressUpdate) {
        // Implement this to handle progress update UI or state
        console.log(`File ${progressUpdate.fileId} is ${progressUpdate.progress}% processed.`);
    }

    private onProcessingComplete(fileId: string) {
        // Implement this to handle processing completion UI or state
        console.log(`File ${fileId} processing complete.`);
    }

    private onProcessingError(fileId: string, error: string) {
        // Implement this to handle processing error UI or state
        console.error(`File ${fileId} processing error: ${error}`);
    }

    async terminateWorker(fileId: string) {
        const worker = this.activeWorkers.get(fileId);
        if (worker) {
            worker.terminate();
            this.activeWorkers.delete(fileId);
            await this.workerStates.setWorkerState({
                fileId,
                status: 'terminated',
                progress: 0,
            });
            this.processNextInQueue();
        }
    }
}

export default FileProcessingManager;
