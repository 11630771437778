// import { PrismaClient } from '@prisma/client' should be used in server-side code.
import { TokenService, WorkerStateService } from "./utils/IndexedDBService";

// not recommended for production use
export const tokenStore = new TokenService(
    'devDB',
    'tokens',
    1,
);

// can be used in production
export const workerStatesStore = new WorkerStateService(
    'daicusAppDB',
    'workerStates',
    1,
);
