import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';

interface Integration {
    name: string;
    description: string;
    is_connected: boolean;
    status: string;
    onClickHandler: () => void;
    logo: string;
}

export const useIntegrations = () => {
    const { isGoogleUserAuthenticated, handleGoogleAuthProcess, disconnectGoogleDrive } = useAuth();
    const [integrations, setIntegrations] = useState<Integration[]>([]);

    const updateIntegrations = useCallback(() => {
        const defaultIntegrations: Integration[] = [
            {
                name: 'Google Drive',
                description: 'Upload files from Google Drive',
                is_connected: isGoogleUserAuthenticated,
                status: isGoogleUserAuthenticated ? 'Disconnect' : 'Connect',
                onClickHandler: isGoogleUserAuthenticated ? disconnectGoogleDrive : handleGoogleAuthProcess,
                logo: "https://upload.wikimedia.org/wikipedia/commons/d/da/Google_Drive_logo.png"
            },
            // Add other integrations here
        ];

        setIntegrations(defaultIntegrations);
    }, [isGoogleUserAuthenticated, handleGoogleAuthProcess, disconnectGoogleDrive]);

    useEffect(() => {
        updateIntegrations();
    }, [updateIntegrations]);

    return { integrations };
};

export default useIntegrations;