import React, { useContext } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { Textarea } from '../../../components/Textarea';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { DataVerificationContext } from '../../../context/DataVerificationContext';

const QueryPage = () => {
    const { userQuery, setUserQuery } = useContext(DataVerificationContext);

    {/* on query suggestion click, fill the query input with the suggestion */ }
    const handleSuggestionClick = (event: React.MouseEvent) => {
        const suggestion = event.currentTarget.textContent as string;
        setUserQuery(suggestion);
    }
    
    return (
        <
        >
            <FormControl
                required
                component="fieldset"
            >
                <Textarea
                    aria-label='Query input'
                    maxRows={6}
                    minRows={3}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setUserQuery(e.target.value)} value={userQuery}
                    placeholder="Enter your query here"
                    sx={{ width: '500px', height: '150px' }} 
                />
            </FormControl>

            {/* query suggestions */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '8px',
                width: '500px'
            }}>
                <Typography variant="body2" gutterBottom sx={{ color: '#616161' , mt: 2}}>
                    <strong>A good example:</strong> <br />
                    Please check whether the <strong>total assets</strong> reported in <u>Q4 Report</u> match the <strong>total assets</strong> numbers shown on the <u>balance sheet</u>.
                    <br /><br />
                    <strong>Tips:</strong>
                    <ul>
                        <li>Clearly reference the source and target files.</li>
                        <li>Provide the specific metrics or information to be verified.</li>
                    </ul>
                </Typography>                
            </Box>
        </>
    );
};

export default QueryPage;