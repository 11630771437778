// Type for google picker document object returned from google picker API
// type GooglePickerResponse = {
// 	description: ""
// 	embedUrl: "<embed_url>"
// 	iconUrl: "https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.spreadsheet"
// 	id: "1grS_jMjMd0NZpBPyHlHpAgBuwGGUuKCo8ZWhUxVbCRA"
// 	lastEditedUtc: 1719245992693
// 	mimeType: "application/vnd.google-apps.spreadsheet"
// 	name: "steve-jobs-quotes"
// 	parentId: "0AG84AEYR0PoiUk9PVA"
// 	serviceId: "spread"
// 	sizeBytes: 0
// 	type: "document"
// 	url: "https://docs.google.com/document/d/<document_id>/edit?usp=drive_web"
// }

enum SopTemplate {
    ISO_8000 = "ISO 8000 Report",
    DAMA_DMBOK = "DAMA DMBOK Report",
    MDM_AUDIT = "MDM Audit Report",
    NONE = "None",
}

type DataProvider = "local" | "googledrive" | "onedrive" | "dropbox" | "box" | "sharepoint" | "s3" | "azure" | "gcp" | "other";
type Designation = "target" | "reference" | "other";

// Make sure this type is consistent with the type of the DataInfo Pydantic model in the backend
// Interface for the file metadata
interface FileMetadata {
    /** Unique identifier for the file */
    id: string;
    /** Name of the file */
    name: string;
    /** Designation of the file (e.g., source, target, reference, etc.) */
    designation: Designation;
    /** Type of the file (e.g., image, video, audio, document, etc.) */
    type: string;
    /** Size of the file in bytes */
    size: number;
    /** Mimetype of the file */
    mimeType: string;
    /** File Description */
    description: string;
    /** Indicates whether the file contains financial data */
    containsFinancialData: boolean;
    /** Provider of the data (e.g., local or cloud) */
    dataProvider: DataProvider;
    /** Path to the file icon */
    iconUrl: string;
    /** URL to access the file */
    blobUrl: string;
}

interface FileUploadResponse {
    name: string;
    type: string;
    size: number;
    path: string;
    created: Date;
    hash: string;
    metadata: {};
    status: string; // 'success' | 'uploading' | 'error';
}

interface AssignmentPostRequestBody {
    prompt: string;
    assignedTo: string;
    data: {
        referenceData: Array<FileMetadata>;
        targetData: Array<FileMetadata>;
    };
    config: AssignmentConfigType;
    status: string;
};


interface AssignmentConfigType {
    sopTemplate: SopTemplate;
}

export type { DataProvider, FileMetadata, FileUploadResponse, AssignmentPostRequestBody, AssignmentConfigType, Designation };
export { SopTemplate };