import React, { useState, useContext } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Select, Tooltip, Typography, ListItem } from '@mui/material';
import { DataVerificationContext } from '../../../context/DataVerificationContext'; 
import { SopTemplate } from '../../../types';               


const AssignmentConfigPage = () => {
    const { assignmentConfig, setAssignmentConfig } = useContext(DataVerificationContext);    

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Select SOP Template
            </Typography>
            <FormControl required>
                <InputLabel id="sop-template-type">SOP</InputLabel>
                <Select
                    labelId="sop-template-type"
                    label="SOP"
                    id="sop-template-type"
                    value={assignmentConfig.sopTemplate}
                    onChange={(e) => setAssignmentConfig({ ...assignmentConfig, sopTemplate: e.target.value as SopTemplate })}
                >
                    {Object.values(SopTemplate).map((template, index) => (
                        <MenuItem key={index} value={template}>
                            {template}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default AssignmentConfigPage;
            

