import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Typography, Stepper, StepLabel, Step, Grid, Box, Snackbar, Alert } from '@mui/material';
import { DataVerificationContext, ErrorMessage, AuditResult } from '../../context/DataVerificationContext';
import { FileMetadata, SopTemplate, AssignmentConfigType, AssignmentPostRequestBody } from '../../types';
import QueryInput from './Pages/QueryPage';
import FileSelect from './Pages/FileSelect';
import ReviewPage from './Pages/ReviewPage';
import ResultPage from './Pages/ResultPage';
import AssignmentConfigPage from './Pages/AssignmentConfigPage';
import { createAssignment } from '../../api/assignments';
import FileProcessingManager, { ProgressUpdate } from '../../utils/FileProcessingManager';

const isDevMode = process.env.REACT_APP_ENV === 'development';

const VerificationPage = () => {
    const [activeStep, setActiveStep] = useState(0);

    const [assignmentId, setAssignmentId] = useState<string>("");
    const [errors, setErrors] = useState<ErrorMessage[]>([]);
    const [selectedReferenceFiles, setSelectedReferenceFiles] = useState<FileMetadata[]>([]);
    const [selectedTargetFiles, setSelectedTargetFiles] = useState<FileMetadata[]>([]); 
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userQuery, setUserQuery] = useState<string>("");
    const [result, setResult] = useState<AuditResult>({ status: "", output: "" });
    const [assignmentConfig, setAssignmentConfig] = useState<AssignmentConfigType>({ sopTemplate: SopTemplate.NONE });
    
    const steps = [
        {
            id: 0,
            name: "Select Reference Data",
            heading: "Select Reference Data",
            component: <FileSelect
                selectedFiles={selectedReferenceFiles}
                setSelectedFiles={setSelectedReferenceFiles}
                errors={errors}
                setErrors={setErrors}
                instructions="Please select <strong>Reference Data</strong> files. Reference Data should include information considered correct or ground truth."
                designation='reference'
            />,
        }, 
        {
            id: 1,
            name: "Select Target Data",
            heading: "Select Target Data",
            component: <FileSelect
                selectedFiles={selectedTargetFiles}
                setSelectedFiles={setSelectedTargetFiles}
                errors={errors}
                setErrors={setErrors}
                instructions="Please select <strong>Target Data</strong> files. Target Data should include information you want to verify."
                designation='target'
            />,
        },
        {
            id: 2,
            name: "Query Input",
            heading: "Query Input",
            component: <QueryInput/>,
        },
        {
            id: 3,
            name: "Configuration",
            heading: "Configuration",
            component: <AssignmentConfigPage/>,
        },
        {
            id: 4,
            name: "Review",
            heading: "Review",
            component: <ReviewPage 
                userQuery={userQuery}
                selectedReferenceFiles={selectedReferenceFiles}
                selectedTargetFiles={selectedTargetFiles}
            />,
        },
        {
            id: 5,
            name: "Results",
            heading: "Results",
            component: <ResultPage/>,
        }
    ]

    const isReviewStep = activeStep === steps.length - 2;

    // const [progress, setProgress] = useState<Record<string, number>>({});

    // const handleProgressUpdate = useCallback((update: ProgressUpdate) => {
    //     setProgress(prev => ({...prev, [update.fileId]: update.progress}));
    // }, []);

    // const handleProcessingComplete = useCallback((fileId: string) => {
    //     setProgress(prev => ({...prev, [fileId]: 100}));
    //     // Any additional logic for completed files
    // }, []);

    // const handleProcessingError = useCallback((fileId: string, error: string) => {
    //     console.error(`Error processing file ${fileId}:`, error);
    //     // Handle error (e.g., show error message to user)
    // }, []);

    const processingManager = useMemo(() => new FileProcessingManager(), []);


    const _validateFileSelection = (files: FileMetadata[]) => {
        if (files.length === 0) {
            setErrors([...errors, { message: "Please select the files." }]);
            return false;
        }

        // file to extract is required
        for (let i = 0; i < files.length; i++) {
            if (files[i].description === "") {
                setErrors([...errors, { message: "File description is required." }]);
                return false;
            }
        }

        return true;
    }

    const _handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        // create an assignment object
        const assignment: AssignmentPostRequestBody = {
            prompt: userQuery,
            assignedTo: "auditor",
            data: {
                referenceData: selectedReferenceFiles,
                targetData: selectedTargetFiles
            },
            config: assignmentConfig,
            status: "pending",
        }

        console.log("Assignment: ", assignment)

        createAssignment(assignment).then((response) => {
            // if only request is successful, set the assignmentId and move to the result page
            // where the user can see the progress of the verification via websocket
            if (response.status === 201) {
                setAssignmentId(response.data.assignment_id)
                setActiveStep((activeStep) => activeStep + 1);

                // do not hide the buttons if the app is in development mode
                if (isDevMode) {
                    setIsSubmitting(true);
                }
            }
        }).catch((error) => {
            console.log(error);
            // if the request is not successful, set the isSubmitting to false
            setIsSubmitting(false);
        })
    }

    const _handleNext = useCallback((e: { preventDefault: () => void; }) => {
        e.preventDefault();
        
        if (!isDevMode) {
            if (activeStep === 0) {
                // TODO: add appropriate validation of the selected files 
                if (!_validateFileSelection(selectedReferenceFiles)) {
                    return;
                }
            } else if (activeStep === 1) {
                if (!_validateFileSelection(selectedTargetFiles)) {
                    return;
                }
            }
        }

        console.log("Active Step: ", activeStep);

        if (activeStep === 0 && processingManager) {
            selectedReferenceFiles.forEach((fileMetadata, index) => {
                processingManager.addFileToQueue(fileMetadata);
            });
        }

        if (activeStep === 1 && processingManager) {
            selectedTargetFiles.forEach((fileMetadata, index) => {
                processingManager.addFileToQueue(fileMetadata);
            });
        }

        setActiveStep((activeStep) => activeStep + 1);
    }, [processingManager, selectedReferenceFiles, selectedTargetFiles, activeStep]);

    const _handleBack = (e: { preventDefault: () => void }) => {
        e.preventDefault();

        if (!isDevMode) {
            if (activeStep === 0) {
                return;
            } else if (activeStep === 1) {
                if (!_validateFileSelection(selectedReferenceFiles)) {
                    return;
                }
            }
        }

        setActiveStep((activeStep) => activeStep - 1);
    };

    return (
        <DataVerificationContext.Provider
            value={{
                assignmentId,
                setAssignmentId,
                errors,
                setErrors,
                selectedReferenceFiles,
                setSelectedReferenceFiles,
                selectedTargetFiles,
                setSelectedTargetFiles,
                userQuery,
                setUserQuery,
                result,
                setResult,
                assignmentConfig,
                setAssignmentConfig,
            }}>
            {/* <Grid item style={{ display: "flex", justifyContent: "center"}}>
                <Typography variant="h5" gutterBottom>
                    {steps[activeStep].heading}
                </Typography>
            </Grid> */}
            {/* Snackbar section */}
            <Snackbar
                open={errors.length > 0}
                autoHideDuration={6000}
                onClose={() => setErrors([])}
            >
                <Alert
                    severity="error"
                    onClose={() => setErrors([])}
                >
                    {errors.map((error, index) => (
                        <div key={index}>{error?.message}</div>
                    ))}
                </Alert>
            </Snackbar>
            {/* Stepper section */}
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, name) => (
                    <Step key={step.id}>
                        <StepLabel>
                            {/* {step.name} */}
                            <Typography variant="subtitle1">{step.name}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            {/* Form section */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                {steps[activeStep].component}
                <Box
                    sx={{
                        display: isSubmitting ? "none" : "flex",
                        position: "fixed",
                        bottom: "calc(10px + 1vh)",
                    }}
                >
                    <Button disabled={activeStep === 0} onClick={_handleBack}>
                        Back
                    </Button>
                    <Button onClick={isReviewStep ? _handleSubmit : _handleNext}
                        variant="contained" 
                        color="primary"
                        sx={{
                            ml: 2, // margin-left: 2 (spacing)
                            padding: '0.5rem 1.5rem',
                            fontWeight: 'bold',
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
                        }}
                    >
                        {isReviewStep ? "Submit" : "Next"}
                    </Button>
                </Box>
            </Box>
        </DataVerificationContext.Provider>
    );
};

export default VerificationPage;




