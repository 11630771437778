import { useState, useCallback, useEffect } from 'react';
import { googleAuthPromise } from '../api/auth';
import { getGoogleCredentialsPromise } from '../api/users';

const isDevEnv = process.env.REACT_APP_ENV === 'development';

const useGoogleAuth = (isUserAuthenticated: boolean) => {
    const [googleAccessToken, setGoogleAccessToken] = useState<string | null>(null);

    const [isGoogleUserAuthenticated, setIsGoogleUserAuthenticated] = useState(!!googleAccessToken);

    const fetchGoogleCredentials = useCallback(async () => {
        if (!isUserAuthenticated) return;

        try {
            const response = await getGoogleCredentialsPromise();
            if (response.data.status === 'success') {
                const token = response.data.credentials.token;
                setGoogleAccessToken(token);
                setIsGoogleUserAuthenticated(true);
            }
        } catch (error) {
            console.error('Failed to fetch Google credentials:', error);
            setIsGoogleUserAuthenticated(false);
        }
    }, [isUserAuthenticated]);

    useEffect(() => {
        fetchGoogleCredentials();
    }, [fetchGoogleCredentials]);

    const handleGoogleAuthProcess = useCallback(async () => {
        try {
            const response = await googleAuthPromise();
            const data = response.data;
            const authWindow = window.open(data.auth_url, 'GoogleOAuth', 'width=500,height=600');

            if (!authWindow) {
                console.error('Popup blocked. Please allow popups and try again.');
                return;
            }

            const handleAuthMessage = (event: MessageEvent) => {
                const allowedOrigin = isDevEnv ? process.env.REACT_APP_DEV_ORIGIN : process.env.REACT_APP_PROD_ORIGIN;

                if (!event.origin.includes(allowedOrigin as string)) return;

                if (event.data.type === 'GOOGLE_OAUTH_CALLBACK') {
                    fetchGoogleCredentials();
                    setIsGoogleUserAuthenticated(true);
                    window.removeEventListener('message', handleAuthMessage);
                    authWindow.close();
                }
            };

            window.addEventListener('message', handleAuthMessage);

            // cleanup event listener on unamount
            return () => {
                window.removeEventListener('message', handleAuthMessage);
            };
        } catch (error) {
            console.error('Failed to initiate Google OAuth process:', error);
        }
    }, [fetchGoogleCredentials]);

    const disconnectGoogleDrive = useCallback(async () => {
        setGoogleAccessToken(null);
        setIsGoogleUserAuthenticated(false);
        // TODO: Add API call to disconnect Google Drive (revoke token)
    }, []);

    return {
        googleAccessToken,
        isGoogleUserAuthenticated,
        handleGoogleAuthProcess,
        disconnectGoogleDrive,
        fetchGoogleCredentials
    };
};

export default useGoogleAuth;