import { axiosInstance } from "./AxiosInstance";
import { AxiosResponse } from "axios";

export const getGoogleCredentialsPromise = async (): Promise<AxiosResponse> => {
    // Retrieve google access token and refresh token
    return await axiosInstance.post('users/me/google-credentials', {
        headers: {
            "Content-Type": "application/json",
        }
    });
}