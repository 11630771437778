import { ReactNode } from "react";
import { Box, Container, CssBaseline, Grid, Toolbar } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { LayoutProvider } from "../../context/BaseLayoutContext";
import { InnerContainer, OuterContainer, MainContainer } from "./style";

// https://mui.com/store/collections/free-react-dashboard/
// https://github.com/mui/material-ui/blob/v5.15.17/docs/data/material/getting-started/templates/dashboard/Dashboard.tsx
// https://github.com/horizon-ui/horizon-ui-chakra-ts/blob/feature/react-table-v8/src/layouts/rtl/index.tsx
interface BaseLayoutProps {
    children: ReactNode;
}

const BaseLayout = ({ children }: BaseLayoutProps) => {
    return (
        <LayoutProvider>
            <OuterContainer>
                <Header />
                <InnerContainer>
                    <Sidebar />
                    <MainContainer>
                        <Toolbar />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {children}
                            </Grid>
                        </Grid>
                    </MainContainer>
                </InnerContainer>
            </OuterContainer>
        </LayoutProvider>
    );
};

export default BaseLayout;