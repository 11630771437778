import { AxiosResponse } from 'axios';
import { axiosInstance } from "./AxiosInstance";

export const checkUserAuthPromise = async (): Promise<AxiosResponse> => {
    return await axiosInstance.get('/auth/check-status');
}

export const loginPromise = async (username: string, password: string): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    return await axiosInstance.post('/auth/token', formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
}

export const googleAuthPromise = async (): Promise<AxiosResponse> => {
    return await axiosInstance.get('/auth/google-auth', {
        headers: {
            "Content-Type": "application/json",
        }
    });
}