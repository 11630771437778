//src/views/Login/index.tsx
import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useLottie } from 'lottie-react';

// TODO: login form is shown during transition from login to home page when the user is already authenticated. Fix this issue.
const Login: React.FC = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    // const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('');

    const { userLogin, isUserAuthenticated } = useAuth();

    // const loginAnimationOptions = {
    //     animationData: require('../../assets/animations/login.json'),
    //     loop: true,
    //     autoplay: true,
    // };
    // const { View: LottieView } = useLottie(loginAnimationOptions);

    // useEffect(() => {
    //     const checkAuthentication = async () => {
    //         // Simulate loading time (remove this in production)            
    //         setLoadingMessage('Authenticating...');
    //         // Simulate authentication check (replace with actual check)
    //         await new Promise(resolve => setTimeout(resolve, 2000));
            
    //         if (isUserAuthenticated) {
    //             navigate('/');
    //         } else {
    //             setIsLoading(false);
    //         }
    //     };

    //     checkAuthentication();
    // }, [isUserAuthenticated, navigate]);

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingMessage('Logging in...');
        try {
            await userLogin(username, password);
            navigate('/');
        } catch (error) {
            // Handle error (e.g. show error message to user)
            console.error('Login failed:', error);
            setError('Invalid username or password');
            // setIsLoading(false);
        }
    }

    return (
        <Container className="login-container">
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Typography variant="h4">Pilot User Login</Typography>
                <form onSubmit={handleLogin} style={{ width: '25%' }}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '1rem' }}
                    >
                        Login
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default Login;