import React from "react";
import { AssignmentConfigType, SopTemplate, FileMetadata as SelectedFile } from "../types";

type ErrorMessage = {
	[key: string]: string;
};

type AuditResult = {
	status: string;
	output: string;
}

type DataVerificationContextType = {
	assignmentId: string;
	setAssignmentId: (taskId: string) => void;
	errors: Array<ErrorMessage>;
	setErrors: (errors: Array<ErrorMessage>) => void;
	selectedReferenceFiles: Array<SelectedFile>;
	setSelectedReferenceFiles: (files: Array<SelectedFile>) => void;
	selectedTargetFiles: Array<SelectedFile>;
	setSelectedTargetFiles: (files: Array<SelectedFile>) => void;
	userQuery: string;
	setUserQuery: (query: string) => void;
	result: AuditResult;
	setResult: (result: AuditResult) => void;
	assignmentConfig: AssignmentConfigType;
	setAssignmentConfig: (config: AssignmentConfigType) => void;
};

const DataVerificationContext =
	React.createContext<DataVerificationContextType>({
		assignmentId: "",
		setAssignmentId: () => { },
		errors: [],
		setErrors: () => { },
		selectedReferenceFiles: [],
		setSelectedReferenceFiles: () => { },
		selectedTargetFiles: [],
		setSelectedTargetFiles: () => { },
		userQuery: "",
		setUserQuery: () => { },
		result: { status: "", output: "" },
		setResult: () => { },
		assignmentConfig: { sopTemplate: SopTemplate.ISO_8000 },
		setAssignmentConfig: () => { },
	});

export { DataVerificationContext };
export type { ErrorMessage, AuditResult };
