// src/services/IndexedDBService.ts
// for development purposes only, do not use in production to store any sensitive data like
// access tokens or refresh tokens
// TODO: need to use more secure data storage methods for production to manage the tokens and worker states in the client

export interface WorkerState {
    fileId: string;
    status: 'processing' | 'complete' | 'terminated';
    progress: number;
}

abstract class IndexedDBService {
    protected db: IDBDatabase | null = null;
    protected storeName: string;
    private dbName: string;
    private dbVersion: number;

    constructor(dbName: string, storeName: string, dbVersion: number) {
        this.dbName = dbName;
        this.storeName = storeName;
        this.dbVersion = dbVersion;
    }

    async openDB(): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => {
                this.db = request.result;
                resolve();
            };
            request.onupgradeneeded = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;
                db.createObjectStore(this.storeName, { keyPath: this.getKeyPath() });
            };
        });
    }

    protected abstract getKeyPath(): string;

    protected async put(storeName: string, item: any): Promise<void> {
        if (!this.db) await this.openDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.put(item);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve();
        });
    }

    protected async get(storeName: string, key: string): Promise<any> {
        if (!this.db) await this.openDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.get(key);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result || null);
        });
    }

    protected async delete(storeName: string, key: string): Promise<void> {
        if (!this.db) await this.openDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.delete(key);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve();
        });
    }

    protected async getAll(storeName: string): Promise<any[]> {
        if (!this.db) await this.openDB();
        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.getAll();

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }
}

export class TokenService extends IndexedDBService {

    constructor(dbName: string, storeName: string, dbVersion: number) {
        super(dbName, storeName, dbVersion);
    }

    protected getKeyPath(): string {
        return 'key';
    }

    async setToken(key: string, value: string): Promise<void> {
        await this.put(this.storeName, { key, value });
    }

    async getToken(key: string): Promise<string | null> {
        const result = await this.get(this.storeName, key);
        return result ? result.value : null;
    }

    async removeToken(key: string): Promise<void> {
        await this.delete(this.storeName, key);
    }
}

export class WorkerStateService extends IndexedDBService {
    constructor(dbName: string, storeName: string, dbVersion: number) {
        super(dbName, storeName, dbVersion);
        this.storeName = storeName;
    }

    protected getKeyPath(): string {
        return 'fileId';
    }

    async setWorkerState(state: WorkerState): Promise<void> {
        await this.put(this.storeName, state);
    }

    async getWorkerState(fileId: string): Promise<WorkerState | null> {
        return await this.get(this.storeName, fileId);
    }

    async deleteWorkerState(fileId: string): Promise<void> {
        await this.delete(this.storeName, fileId);
    }

    async getAllWorkerStates(): Promise<WorkerState[]> {
        return await this.getAll(this.storeName);
    }
}
