import { Box, Container, styled } from "@mui/material";

export const OuterContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "inherit",
    minHeight: "100vh",
    overflow: "hidden",
}));

export const InnerContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    height: "inherit",
}));

export const MainContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: "hidden",
    height: "inherit",
}));
