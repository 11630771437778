import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import useGoogleAuth from '../hooks/useGoogleAuth';
import useUserAuth from '../hooks/useUserAuth';

interface AuthContextType {
    user: string | null;
    setUser: (userData: string | null) => void;
    isUserAuthenticated: boolean;
    setIsUserAuthenticated: (isAuthenticated: boolean) => void;
    googleAccessToken: string | null;
    isGoogleUserAuthenticated: boolean;
    handleGoogleAuthProcess: () => void;
    disconnectGoogleDrive: () => void;
    fetchGoogleCredentials: () => void;
    userLogin: (username: string, password: string) => Promise<void>;
    userLogout: () => void;
    isLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user, setUser, isUserAuthenticated, setIsUserAuthenticated, userLogin, userLogout, isLoading } = useUserAuth();
    const { googleAccessToken, isGoogleUserAuthenticated, handleGoogleAuthProcess, disconnectGoogleDrive, fetchGoogleCredentials } = useGoogleAuth(isUserAuthenticated);

    return (
        <AuthContext.Provider value={{
            user,
            setUser,
            isUserAuthenticated,
            setIsUserAuthenticated,
            googleAccessToken,
            isGoogleUserAuthenticated,
            handleGoogleAuthProcess,
            disconnectGoogleDrive,
            fetchGoogleCredentials,
            userLogin,
            userLogout,
            isLoading,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
