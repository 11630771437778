// https://www.joinplank.com/articles/axios-interceptors-when-should-you-use-them
// https://medium.com/@sanchit0496/what-is-axios-interceptor-how-to-handle-refresh-tokens-in-frontend-7e8bbdbb8ac9

import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { tokenStore } from "../db";

const isDevEnv = process.env.REACT_APP_ENV === 'development';
const MAX_RETRIES = 3;
let retryCount = 0;

// Create an axios instance
const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 10000,
	withCredentials: true, // this allows sending cookies with requests
});

const handleLogout = async () => {
	console.log("Logging out user");
	// Clear user access token and google access token from indexedDB
	await tokenStore.removeToken('userAccessToken');
	await tokenStore.removeToken('googleAccessToken');

	// Redirect to login page
	window.location.href = '/login';
}

// Function to get a new access token using the refresh token
export const refreshToken = async (): Promise<string> => {
	const response = await axiosInstance.post('/auth/refresh-token');
	return response.data.access_token;
};

// no need to attach the token manually to the Authorization header since
// the authentication token is stored in an HttpOnly cookie, the browser
// will handle sending the cookie automatically.
// axiosInstance.interceptors.request.use(
// 	async (config: InternalAxiosRequestConfig) => {
// 		const token = await tokenStore.getToken('userAccessToken');
// 		if (token) {
// 			config.headers.Authorization = `Bearer ${token}`;
// 		}

// 		return config;
// 	},
// 	(error) => Promise.reject(error)
// );

// Add a response interceptor
// axiosInstance.interceptors.response.use(
// 	(response) => response,
// 	async (error) => {
// 		const originalRequest = error.config;
// 		if (error.response?.status === 401 && !originalRequest._retry && retryCount < MAX_RETRIES) {
// 			originalRequest._retry = true;
// 			retryCount++;
// 			try {
// 				await refreshToken();
// 				// if (isDevEnv) await tokenStore.setToken('userAccessToken', newAccessToken);
// 				// axiosInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
// 				// originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
// 				return axiosInstance(originalRequest);
// 			} catch (err) {
// 				console.error('Failed to refresh token:', err);
// 				await handleLogout();
// 			}
// 		} else if (retryCount >= MAX_RETRIES) {
// 			console.error('Max retries reached');
// 			await handleLogout();
// 		}
// 		return Promise.reject(error);
// 	}
// );

export { axiosInstance };
